/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  cursor: pointer;
}

p {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: "Open Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf) format("truetype");
}
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  font-family: "Open Sans", Helvetica, sans-serif;
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
}

html {
  min-height: 100vh;
}

/* Remove the Overflow on window */
html, body, #root, .App {
  overflow-x: hidden; /* Hide any overflow from these elements */
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
}
.App .page {
  height: 100%;
}

/* Variables from brand tokes (sent by Rich) */
.audio-body {
  text-align: center;
  font-family: Arial, sans-serif;
}
.audio-body .audio-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.audio-body .audio-header button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.audio-body .audio-header button:hover {
  background-color: #21a1f1;
}
.audio-body .audio-header p {
  font-size: 1.2em;
}
.audio-body .audio-header strong {
  font-weight: bold;
}

.footer-container {
  display: flex;
  position: sticky;
  top: 100%;
  width: 100%;
  background: #F7F9FD;
  flex-shrink: 1;
  max-height: 10vh;
  z-index: 100;
  justify-content: space-evenly;
  gap: 24px;
  /******************/
  /******************/
}
.footer-container .footer-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* This pushes the footer to the bottom */
}
.footer-container .footer-item .content {
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  line-height: 115%;
  display: flex;
  align-items: center;
}
.footer-container .footer-item .content.label {
  color: var(--text-text-primary, #151515);
  font-weight: 700;
}
.footer-container .footer-item .time-up {
  width: 48px;
  color: #A7A7A7;
  font-weight: 600;
  margin-right: 35px;
}
.footer-container .footer-item .time-up {
  width: 109px;
  height: 71px;
  margin-left: 8px;
  margin-right: 40px;
  color: #A7A7A7;
}
.footer-container .footer-item button {
  margin: 1.167vh 0;
}

.footer-item.bar {
  display: flex;
  gap: 5px;
  width: 50%;
}
.footer-item.bar .content.page {
  width: 60px;
}

.footer-body.left {
  padding-right: 0;
}

.footer-body.center {
  width: 100%;
  padding-right: 0;
}

.footer-body.right {
  margin-left: auto;
  padding-left: 0;
}

div.select__value-container {
  display: flex !important;
  align-content: center;
  height: 24px;
  padding: 12px;
}
div.select__value-container .select__input-container {
  height: 24px;
}

.select__control, div.select__menu {
  width: 420px;
}

.option-container {
  display: flex;
  gap: 10px;
}
.option-container .option-details p + p {
  margin-top: 5px;
}

.stepper {
  display: flex;
  flex-direction: column; /* Keeps the stepper in a column layout */
  align-items: center; /* Center items horizontally */
  width: 100%; /* Ensure the stepper takes the full width of its parent */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.progress-bar {
  display: flex;
  width: 100%; /* Make sure the progress bar takes up the full width */
  max-width: 825px; /* Set the maximum width */
  flex-grow: 1; /* Allow the progress bar to grow */
  flex-shrink: 1; /* Allow the progress bar to shrink */
  box-sizing: border-box;
}

.RSPBprogressBar {
  height: 8px;
  width: 100%;
  max-width: 825px; /* Set the maximum width */
  flex-grow: 1; /* Allow the progress bar to grow */
  flex-shrink: 1; /* Allow the progress bar to shrink */
  line-height: 1;
  border-radius: 9px;
  background: #E8E8E8 !important;
  z-index: 0;
  margin: 20px auto;
}

.RSPBstep {
  flex-grow: 1; /* Allow each step to grow */
  flex-shrink: 1; /* Allow each step to shrink */
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #2B6BBE !important;
  z-index: -1;
}

@media screen and (max-width: 480px) {
  .indexedStep {
    width: 15px;
    height: 15px;
    font-size: 6px;
  }
}
.stepper-btn {
  display: flex;
  flex-shrink: 1;
  max-height: 90px;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #2B6BBE;
  color: var(--text-inverse-text-primary, #FEFEFE);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* standard-scale/p-bold */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.stepper-btn:hover {
  color: var(--icon-icon-secondary-hover, #333);
  background: var(--text-inverse-text-primary, #FEFEFE);
}

.stepper-btn:focus {
  opacity: 0.8;
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #2B6BBE;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

.stepper-btn[disabled], .play-btn[disabled] {
  pointer-events: none; /* Prevent click events */
  opacity: 0.5; /* Reduce opacity to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  background-color: #D3D3D3; /* or #CCCCCC */
  color: #666666; /* Adjust text color if needed */
}

.stepper-btn[disabled]:hover, .play-btn[disabled]:hover {
  background-color: inherit; /* Prevent color change on hover */
  color: inherit; /* Prevent color change on hover */
}

.play-btn, .create-btn {
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid #000;
  color: var(--icon-icon-secondary-hover, #333);
  background: var(--icon-inverse-icon-primary, #FEFEFE);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* standard-scale/p-bold */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.create-btn {
  background: var(--icon-icon-secondary, #333);
  color: var(--text-inverse-text-primary, #FEFEFE);
}

.play-btn {
  width: 232px;
}

.play-btn:hover:not(:focus) {
  background-color: rgba(217, 217, 217, 0.3);
  background: var(--icon-icon-secondary, #333);
}
.play-btn:hover:not(:focus) span svg path {
  fill: var(--text-inverse-text-primary, #FEFEFE);
}
.play-btn:hover:not(:focus) span {
  color: var(--text-inverse-text-primary, #FEFEFE);
}

.create-btn:hover:not(:focus) {
  color: var(--icon-icon-secondary-hover, #333);
  background-color: rgba(217, 217, 217, 0.3);
}

.play-btn:focus, .create-btn:focus {
  opacity: 0.8;
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #2B6BBE;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}

.translation-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.translation-column .listen-countdown {
  display: flex;
  flex-direction: column;
}
.translation-column .listen-countdown .countdown {
  margin-top: 20.33vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--accents-accent-03-medium, #003057);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 38.4px */
}
.translation-column .listen-countdown .countdown-number {
  margin-left: 40px;
  color: var(--text-text-primary, #151515);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  /* 55.2px */
}
.translation-column .listen-countdown .speaker {
  margin-top: 65px;
}
.translation-column .listen-countdown .microphone {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.speak-timer-container {
  display: inline-flex;
  gap: 67px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.speaker-container {
  display: flex;
  align-items: center;
}
.speaker-container span {
  color: var(--accents-accent-03-medium, #003057);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.speak-timer {
  display: inline-flex;
  height: 36px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0.8vh;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}
.speak-timer span {
  color: var(--text-text-primary, #151515);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 25.6px */
}

.signin-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
}
.signin-form-content .signin-header {
  margin-top: 9px;
  margin-bottom: 48px;
}
.signin-form-content div.row.create-account {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.signin-form-content .create-account-link {
  color: var(--Neutral-7, #8CA2C0);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
}
.signin-form-content .title {
  color: var(--text-text-primary, #151515);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  text-align: center;
  /* title/large */
  margin-bottom: 16px;
}
.signin-form-content .login-group {
  display: flex;
  flex-direction: column;
}
.signin-form-content .login-group .form-group .label-element {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  width: 360px;
  height: auto;
  margin-bottom: 9px;
}
.signin-form-content .login-group .form-group .label-element .label-icon {
  margin-left: 15px;
  width: 17.99px;
  height: 18.89px;
}
.signin-form-content .login-group .form-group .label-element label {
  display: flex;
  align-items: center;
}
.signin-form-content .login-group .form-group .row {
  margin-bottom: 35px;
  width: 360px;
}
.signin-form-content .login-group .form-group .row .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
  width: 360px;
}
.signin-form-content .login-group .form-group div.row:nth-child(2) {
  margin-top: 35px;
}
.signin-form-content .login-group .row.signin-terms {
  width: 360px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.signin-form-content .login-group p.trouble-element {
  color: var(--Dark-B, #486284);
  /* Link */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 152.381% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.signin-form-content .login-group button.adopt-btn {
  margin-top: 25px;
  margin-bottom: 35px;
}

.create-account-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 970px;
  flex-shrink: 0;
  border-radius: 8px;
  /*Font*/
  color: var(--Neutral-10, #486284);
  margin-top: 26px;
}
.create-account-form-content .header {
  margin-top: 9px;
  margin-bottom: 96px;
}
.create-account-form-content div.segment.create-account,
.create-account-form-content div.row.create-account {
  color: var(--Neutral-7, #8CA2C0);
  text-align: center;
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.create-account-form-content .title {
  color: var(--text-text-primary, #151515);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  text-align: center;
  text-align: center;
  letter-spacing: 0.5px;
}
.create-account-form-content .login-group {
  display: flex;
  flex-direction: column;
}
.create-account-form-content .login-group .form-group .name-group {
  display: flex;
  flex-direction: row;
}
.create-account-form-content .login-group .form-group .label-element {
  font-size: 16px;
  line-height: 24px;
}
.create-account-form-content .login-group .form-group .checkbox.label-element {
  font-size: 20px;
  line-height: 32px;
}
.create-account-form-content .login-group .form-group .label-element {
  font-style: normal;
  font-weight: 400;
  /* 150% */
  letter-spacing: 0.5px;
  height: auto;
  margin-bottom: 9px;
}
.create-account-form-content .login-group .form-group .label-element .label-icon {
  margin-left: 10px;
  width: 17.99px;
  height: 18.89px;
}
.create-account-form-content .login-group .form-group .label-element label {
  display: flex;
  align-items: center;
}
.create-account-form-content .login-group .form-group .label-element.checkbox-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.create-account-form-content .login-group .form-group .label-element.checkbox-group label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.create-account-form-content .login-group .segment .col input {
  width: 267px;
}
.create-account-form-content .login-group .row .col input {
  width: 550px;
}
.create-account-form-content .login-group .row,
.create-account-form-content .login-group .segment {
  margin-bottom: 35px;
}
.create-account-form-content .login-group .row .col input,
.create-account-form-content .login-group .segment .col input {
  box-sizing: border-box;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Neutral-10, #486284);
}
.create-account-form-content .login-group .form-group .name-group div.segment:nth-child(2) {
  margin-left: 18px;
}
.create-account-form-content .login-group .signin-terms {
  width: 360px;
  color: var(--Dark-B, #486284);
  /* Body: Desktop */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: 0.5px;
}
.create-account-form-content .login-group p.trouble-element {
  color: var(--Dark-B, #486284);
  /* Link */
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 152.381% */
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.create-account-form-content .create-btn {
  margin-top: 20px;
  width: 100%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: #FFF;
  pointer-events: auto;
  z-index: 20;
}

path.CircularProgressbar-path.time-left {
  stroke: rgb(255, 255, 255) !important;
}

path.CircularProgressbar-path.time-test, .featuredChart {
  width: 154px !important;
}

.countdown-bar-container {
  margin-top: 8% !important;
  max-height: 321.3px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Container for the countdown bar */
}
.countdown-bar-container .countdown-bar {
  position: relative;
  max-width: 299.3px;
  /* Full width of the parent container */
  height: 8px;
  /* Adjust height as needed */
  background-color: #e0e0e0;
  /* Light gray background for the bar */
  border-radius: 10px;
  /* Optional: rounded corners for a smooth look */
  transform: rotate(180deg);
  /* Flip the bar and its content 180 degrees */
}
.countdown-bar-container .countdown-bar .subbar {
  width: 299.3px;
  /* Full width of the parent container */
  height: 8px;
}
.countdown-bar-container .countdown-bar .countdown-bar-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  z-index: 100000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  /* Full height of the container */
  background: #2B6BBE;
  transition: width 0.5s linear;
  /* Smooth transition for width changes */
}
.countdown-bar-container .override-btn[disabled] {
  pointer-events: none; /* Prevent click events */
  opacity: 0.5; /* Reduce opacity to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  background-color: #D3D3D3; /* or #CCCCCC */
  color: #666666; /* Adjust text color if needed */
}
.countdown-bar-container .override-btn {
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid #000;
  background: var(--icon-icon-secondary, #333);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* standard-scale/p-bold */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}
.countdown-bar-container .override-btn span {
  color: white;
}
.countdown-bar-container .override-btn {
  width: 116px;
  margin-top: 48px;
}
.countdown-bar-container .override-btn:hover:not(:focus) {
  background: var(--icon-inverse-icon-primary, #FEFEFE);
}
.countdown-bar-container .override-btn:hover:not(:focus) span {
  color: var(--icon-icon-secondary-hover, #333);
}
.countdown-bar-container .override-btn:focus {
  opacity: 0.8;
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #2B6BBE;
  color: rgba(37, 37, 37, 0.9);
  background-color: rgba(217, 217, 217, 0.3);
}
.countdown-bar-container .override-btn:focus span {
  color: var(--icon-icon-secondary-hover, #333);
}

.duration-message {
  margin-top: 24px;
  width: 275px;
  color: var(--accents-accent-03-medium, #003057);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 25.2px */
}

.hide {
  display: none;
}

.signin-page {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-color: white;
  background-position: center -10%, center bottom, right top;
  background-size: contain, contain, contain;
}
.signin-page .login-container {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
}
.signin-page .login-container .branding svg {
  margin-bottom: 2.5rem;
}
.signin-page .login-container .branding p {
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
  max-width: 25rem;
}
.signin-page .login-container form.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
  background: #fff;
  border-radius: 0.5rem;
  border: solid black 1px;
  padding: 2rem;
}
.signin-page .login-container form.container .header {
  display: flex;
  width: 100%;
  align-items: center;
}
.signin-page .login-container form.container .header h3 {
  color: #05001c;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33;
}
.signin-page .login-container form.container .question-icon {
  margin-left: auto;
}
.signin-page .login-container form.container .item {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}
.signin-page .login-container form.container .item .asterick-style {
  color: #df41cf !important;
}
.signin-page .login-container form.container .page-link {
  color: #2b6bbe;
  color: #512eab;
  font-weight: 800;
  opacity: 0.7;
}
.signin-page .login-container form.container .page-link.sentence-connector {
  margin-left: 5px;
  /* Adjust space as needed */
}
.signin-page .login-container form.container a.page-link {
  font-weight: 800;
}
.signin-page .login-container form.container .custom-link {
  border-bottom: none;
  text-decoration: none !important;
  /* Removes the default underline */
  cursor: pointer;
  position: relative;
  /* Allows for positioning of the custom underline */
  padding-bottom: 15px;
}
.signin-page .login-container form.container .custom-link {
  color: #848192 !important;
  /* Optionally, remove margin-bottom for the last item */
}
.signin-page .login-container form.container .custom-link::after {
  content: "";
  /* Creates a pseudo-element for the underline */
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  /* Sets the space between text and underline */
  height: 1px;
  /* Adjust thickness of the underline */
  background-color: #df41cf;
  /* Matches the underline color to the text color */
  height: 2px;
  margin-bottom: 15px;
}
.signin-page .login-container form.container .custom-link:last-child {
  margin-bottom: 0;
  /* Prevents extra space below the last item */
}
.signin-page .login-container form.container hr {
  width: 100%;
  /* Full width */
  height: 2px;
  /* Set a height for the line */
  background-color: #5B5EA3;
  /* Color of the line */
  border: none;
  /* Remove the default border */
}
.signin-page .login-container form.container .create-account-message {
  display: flex;
  justify-content: center;
}
.signin-page .login-container form.container .form-input input {
  padding: 1rem;
  position: relative;
  font-size: 1rem;
  width: 100%;
  height: 2rem;
  border: 1px solid #BCB9CC;
}
.signin-page .login-container form.container .form-input input::placeholder {
  position: absolute;
  top: 8px;
  font-size: 0.875rem;
  /* Optional, for consistent styling */
}
.signin-page .login-container form.container .form-input input.invalid {
  border-color: red;
}
.signin-page .login-container form.container .form-input .asterick {
  position: "relative";
  display: "inline-block";
}
.signin-page footer {
  margin-top: 11.875rem;
}

.signin-page .btn {
  border-radius: 100px;
  background: transparent;
  border: 2px solid #2b6bbe;
  border: 2px solid black;
  padding: 1rem 2rem;
  color: #2b6bbe;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  /* color variations */
  /* Size variations */
  /* Icon position variation */
}
.signin-page .btn svg {
  color: #2b6bbe;
}
.signin-page .btn.primary {
  background: #2b6bbe;
  color: #fff;
}
.signin-page .btn.primary svg {
  color: #fff;
}
.signin-page .btn.primary:hover {
  border-color: #3586CC;
  background-color: #3586CC;
}
.signin-page .btn.secondary {
  background-color: transparent;
}
.signin-page .btn.secondary:hover {
  background-color: white;
}
.signin-page .btn.secondary-inverse {
  border-color: #fff;
  color: #fff;
}
.signin-page .btn.tertiary {
  background-color: #FFCE00;
  border-color: #FFCE00;
  color: #2b6bbe;
}
.signin-page .btn.tertiary:hover {
  border-color: #FFE680;
  background-color: #FFE680;
}
.signin-page .btn.purple {
  background-color: #5B5EA3;
  border-color: #5B5EA3;
  color: #fff;
}
.signin-page .btn:disabled {
  background-color: #E1DFEA;
  border-color: #E1DFEA;
  color: #05001c !important;
  cursor: default;
}
.signin-page .btn.small {
  padding: 0.5rem 1rem;
}
.signin-page .btn.after {
  flex-direction: row-reverse;
}

.volume-slider {
  position: fixed;
  right: 0;
  top: 182px;
  z-index: 100;
  width: 33px;
  height: 133px;
  padding: 4px;
}

html {
  width: 100vw;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 90px);
}
.column .listen-countdown {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 90px);
}
.column .listen-countdown .countdown {
  position: relative;
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--accents-accent-03-medium, #003057);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  /* 38.4px */
}
.column .listen-countdown .countdown .text {
  height: 37px;
  text-align: center;
}
.column .listen-countdown .countdown .duration {
  position: absolute;
  bottom: -64px;
  color: #003057;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 36px */
}
.column .listen-countdown .countdown .count-down-text {
  width: 380px;
  text-align: center;
}
.column .listen-countdown .countdown-number {
  margin-left: 40px;
  color: var(--text-text-primary, #151515);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  /* 55.2px */
}
.column .listen-countdown .speaker {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  position: relative;
  width: 100%;
}
.column .listen-countdown .speaker .headphones {
  position: absolute;
  top: -30px;
  left: calc(50% - 130px);
}
.column .listen-countdown .speaker .system-response {
  display: flex;
  justify-content: center;
  width: 100%;
}
.column .listen-countdown .speaker .system-response .alternative {
  margin-top: 54px;
  width: 33.33%;
  height: 140px;
}
.column .listen-countdown .speaker .system-response .base {
  width: 100%;
  height: 140px;
}
.column .listen-countdown .microphone-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  height: 200px;
  width: 100%;
}
.column .listen-countdown .microphone-container .microphone {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.column .listen-countdown .microphone-container .featuredChart {
  position: absolute;
  width: 132px !important;
  flex-shrink: 1;
  z-index: 100;
}
.column .listen-countdown .microphone-container .microphone-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
}
.column .listen-countdown .microphone-container .microphone-toggle svg {
  display: flex;
  height: auto;
  flex-shrink: 1;
}
.column .listen-countdown .speak-timer-container {
  position: absolute;
  bottom: -60px;
  z-index: 100;
}

.page .language-select-container {
  display: inline-flex;
  align-items: center;
  height: calc(100vh - 90px);
  gap: 20%;
}
.page .language-select-container .select.instructions {
  width: 405px;
  margin-left: 43px;
}
.page .language-select-container .select.instructions .title {
  width: 346px;
  margin-bottom: 10px;
  color: var(--text-text-primary, #151515);
  font-feature-settings: "clig" off, "liga" off;
  /* standard-scale/h1-headline */
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  /* 57.5px */
}
.page .language-select-container .select.instructions .scenario {
  width: 405px;
}
.page .language-select-container .select.instructions .body {
  min-width: 406px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 19.8px */
}

.advice-container {
  /* Ensure padding does not affect the total width */
  position: relative;
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  padding-top: 67px;
  padding-right: 36px;
  gap: 24px;
  width: 100%;
  height: 100%;
}
.advice-container .details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30vw;
  min-height: 180px;
  margin-left: 36px;
  max-height: 75vh;
  justify-content: space-between;
}
.advice-container .details-container .description-wrapper {
  display: flex;
  max-width: 50vw;
  flex-direction: column;
  padding: 24px 50px;
  max-height: 60%;
  /**************/
  /***************/
}
.advice-container .details-container .description-wrapper div.title {
  display: flex;
  width: 100%;
  flex-shrink: 1;
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.advice-container .details-container .description-wrapper span.instructions {
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  flex-shrink: 1;
  line-height: 150%;
  font-size: clamp(0.75rem, 1.5vw, 1rem);
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  line-height: 1.2;
  word-wrap: break-word;
}
.advice-container .details-container .description-wrapper span.details,
.advice-container .details-container .description-wrapper span.instructions {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Open Sans";
  white-space: normal;
  width: 100%;
  height: 100%;
}
.advice-container .details-container .description-wrapper span.details {
  margin-top: 3%;
}
.advice-container .details-container .description-wrapper span.instructions {
  margin-top: 4%;
  font-size: clamp(1.125rem, 30vw, 1.25rem);
}
.advice-container .details-container .microphone-wrapper {
  max-height: 50%;
}
.advice-container .details-container .microphone-wrapper .recording-message {
  color: var(--accents-accent-03-medium, #003057);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 24px */
  margin-bottom: 45px;
}
.advice-container .details-container .count-down-text {
  width: 380px;
  text-align: center;
  margin-bottom: 5%;
  color: var(--accents-accent-03-medium, #003057);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  z-index: 24;
  position: absolute;
  bottom: 25px;
}
.advice-container .details-container .microphone-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  height: 200px;
}
.advice-container .details-container .microphone-container .microphone {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.advice-container .details-container .microphone-container .featuredChart {
  position: absolute;
  width: 132px !important;
  flex-shrink: 1;
  z-index: 100;
}
.advice-container .details-container .microphone-container .microphone-toggle {
  position: absolute;
}
.advice-container .backpain-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  max-width: 50vw;
  height: 100%;
  max-height: 75vh;
  padding: 32px;
  border-radius: 24px;
  border: var(--stroke-interactive-default, 1px) solid rgba(0, 0, 0, 0.07);
  background: #FBFCFE;
}
.advice-container .backpain-image-container .image-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  height: 100%;
}
.advice-container .backpain-image-container .image-row .image-item {
  position: relative;
  flex-basis: calc(50% - 20px);
  /* Adjusted to allow flexible width */
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* Prevents overflow */
}
.advice-container .backpain-image-container .image-row .image-item .image-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: auto;
}
.advice-container .backpain-image-container .image-row .image-item .image-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  max-height: 256px;
  max-width: 100%;
  /* Prevents inline element spacing issues */
  border-radius: 0.8vh !important;
  flex-grow: 0;
  /* Allow images to grow */
  flex-shrink: 1;
  /* Allow images to shrink */
  flex-basis: calc(50% - 20px);
  /* Basis for the image size calculation, accounting for gap */
  /* Ensure images don't exceed their container */
  /* Adjust for three rows */
  object-fit: contain;
}
.advice-container .backpain-image-container .image-row .image-item .image-wrapper .image-circle {
  position: absolute;
  /* Adjusted to allow flexible width */
  top: 10px;
  right: 10px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  z-index: 1;
}
.advice-container .backpain-image-container .image-row .image-item .image-wrapper .red-circle {
  background: #D00909;
}
.advice-container .backpain-image-container .image-row .image-item .image-wrapper .green-circle {
  background: #09D029;
}
.advice-container .image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  max-width: 50vw;
  max-height: 75vh;
  padding: 32px;
  border-radius: 24px;
  border: var(--stroke-interactive-default, 1px) solid rgba(0, 0, 0, 0.07);
  background: #FBFCFE;
}
.advice-container .image-container img {
  display: block;
  /* Prevents inline element spacing issues */
  border-radius: 0.8vh !important;
  flex-grow: 0;
  /* Allow images to grow */
  flex-shrink: 1;
  /* Allow images to shrink */
  flex-basis: calc(50% - 20px);
  /* Basis for the image size calculation, accounting for gap */
  max-width: calc(50% - 10px);
  /* Ensure images don't exceed their container */
  max-height: calc(33.333% - 10px);
  /* Adjust for three rows */
  object-fit: cover;
}

.hide {
  display: none;
}

.chat-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 67px;
  padding-right: 36px;
  padding-bottom: 55px;
  gap: 24px;
  width: 100%;
  height: 100%;
}
.chat-container .details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30vw;
  margin-left: 36px;
  max-height: 75vh;
}
.chat-container .details-container .description-wrapper {
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  padding: 24px 50px;
  padding-bottom: 0;
  padding-top: 15px;
}
.chat-container .details-container .description-wrapper span.title,
.chat-container .details-container .description-wrapper span.details,
.chat-container .details-container .description-wrapper span.instructions {
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  flex-shrink: 1;
  line-height: 150%;
  font-size: clamp(0.75rem, 1.5vw, 1rem);
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  line-height: 1.2;
  word-wrap: break-word;
}
.chat-container .details-container .description-wrapper span.title {
  font-size: 24px;
  font-weight: 700;
}
.chat-container .details-container .description-wrapper span.details,
.chat-container .details-container .description-wrapper span.instructions {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Open Sans";
  white-space: normal;
  width: 100%;
  height: 100%;
}
.chat-container .details-container .description-wrapper span.details {
  margin-top: 3%;
}
.chat-container .details-container .description-wrapper span.instructions {
  margin-top: 4%;
  font-size: clamp(1.125rem, 30vw, 1.25rem);
}
.chat-container .image-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  min-width: 46vw;
  max-height: 770px;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 24px;
  border: var(--stroke-interactive-default, 1px) solid rgba(0, 0, 0, 0.07);
}
.chat-container .chat-wrapper {
  height: 243.01px;
  max-height: 40%;
}
.chat-container .chat-wrapper .counters-and-microphone {
  margin-top: 8%;
  width: 299.3px;
  height: 243.01px;
  max-height: 300px;
}
.chat-container .chat-wrapper .microphone-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  height: 243.01px;
  max-height: 300px;
}
.chat-container .chat-wrapper .microphone-wrapper .recording-message {
  color: var(--accents-accent-03-medium, #003057);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
}
.chat-container .chat-wrapper .microphone-wrapper .microphone-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  max-height: 200px;
  min-height: 176px;
}
.chat-container .chat-wrapper .microphone-wrapper .microphone-container .microphone {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-container .chat-wrapper .microphone-wrapper .microphone-container .featuredChart {
  position: absolute;
  width: 132px !important;
  flex-shrink: 1;
  z-index: 100;
}
.chat-container .chat-wrapper .microphone-wrapper .microphone-container .microphone-toggle {
  position: absolute;
}
.chat-container .chat-wrapper .count-down-text {
  width: 380px;
  text-align: center;
  margin-bottom: 5%;
  color: var(--accents-accent-03-medium, #003057);
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  z-index: 24;
  position: absolute;
  bottom: 25px;
}

.plan {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 10px;
  border-radius: 7px;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.item {
  height: calc(50% - 57px);
}

.original-container {
  background: rgba(235, 243, 255, 0.75);
  color: #2b6bbe;
}

.new-container {
  color: #722351;
  background: rgba(241, 223, 234, 0.3);
}

.row-0,
.row-3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 16px;
}

.row-0 {
  margin-bottom: 16px;
}

.block-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  height: 60%;
}

.image-label-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-basis: calc(10% - 10px);
  max-width: calc(20% - 10px);
  flex-shrink: 1;
  flex-grow: 1;
  text-align: center;
}

.image-background {
  border-radius: 15px !important; /* Round the corners of the image */
  object-fit: contain; /* Ensures the image scales without cropping */
  width: 100%; /* The image will fill the width of its parent container */
  height: 100%; /* The height will adjust based on the aspect ratio of the image */
}

.original-plan,
.new-plan {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  height: 100%;
  text-align: center;
  padding: 12px;
}
.original-plan img,
.new-plan img {
  width: 100%;
  overflow: hidden;
}
.original-plan p.title,
.new-plan p.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.original-plan p.time,
.new-plan p.time {
  font-size: 20px;
  font-weight: 600;
}
.original-plan p.subtitle,
.new-plan p.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}
.original-plan ul,
.new-plan ul {
  list-style: disc;
  text-align: left;
  margin-left: 20px;
}
.original-plan ul li,
.new-plan ul li {
  color: #722351;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.original-plan {
  background: #ebf3ff;
}
.original-plan p {
  color: #2b6bbe;
}
.original-plan svg {
  fill: #2b6bbe;
}

.new-plan {
  background: rgba(241, 223, 234, 0.6);
}
.new-plan p {
  color: #722351;
}
.new-plan svg {
  fill: #722351;
}

.label {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  color: var(--text-text-secondary, #333);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.labels-1,
.labels-4 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.svg-icon {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.hide {
  display: none !important;
}

/* Breakpoint for height 800px or below */
@media (max-height: 800px) {
  .chat-container {
    padding-top: 47px;
    padding-bottom: 35px;
  }
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page .instructions-container {
  display: inline-flex;
  align-items: center;
  height: calc(100vh - 90px);
  gap: 10%;
}
.page .instructions-container .scenario.instructions {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 405px;
  margin-left: 40px;
}
.page .instructions-container .scenario.instructions .title {
  margin-left: 32px;
  width: 346px;
  margin-bottom: 10px;
  color: var(--text-text-primary, #151515);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  /* 55.2px */
}
.page .instructions-container .scenario.instructions .scenario {
  width: 405px;
}
.page .instructions-container .scenario.instructions .body {
  margin-left: 32px;
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 35.2px */
  /* 19.8px */
}
.page .instructions-container .scenario.instructions .duration-message {
  margin-top: 48px;
  left: -24px;
  display: flex;
  width: 455px;
  height: 151px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #FBFCFE;
  padding: 32px 24px;
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-align: left;
}
.page .instructions-container .scenario.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page .hidden-element {
  visibility: hidden;
}

.opinion-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 67px;
  padding-right: 36px;
  padding-bottom: 55px;
  gap: 24px;
  width: 100%;
  height: 100%;
}
.opinion-container .details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30vw;
  margin-left: 36px;
  max-height: 75vh;
}
.opinion-container .details-container .description-wrapper {
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  padding: 24px 50px;
  padding-bottom: 0;
  padding-top: 15px;
}
.opinion-container .details-container .description-wrapper span.title,
.opinion-container .details-container .description-wrapper span.details,
.opinion-container .details-container .description-wrapper span.instructions {
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  flex-shrink: 1;
  line-height: 150%;
}
.opinion-container .details-container .description-wrapper span.title {
  font-size: 24px;
  font-weight: 700;
}
.opinion-container .details-container .description-wrapper span.details,
.opinion-container .details-container .description-wrapper span.instructions {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Open Sans";
  white-space: normal;
  width: 100%;
  height: 100%;
}
.opinion-container .details-container .description-wrapper span.details {
  margin-top: 3%;
}
.opinion-container .details-container .description-wrapper span.instructions {
  margin-top: 4%;
  font-size: clamp(1.125rem, 30vw, 1.25rem);
}
.opinion-container .image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  max-width: 50vw;
  height: 100%;
  max-height: 75vh;
  padding: 32px;
  border-radius: 24px;
  border: var(--stroke-interactive-default, 1px) solid rgba(0, 0, 0, 0.07);
  background: #FBFCFE;
}
.opinion-container .image-container .image-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  height: 100%;
}
.opinion-container .image-container .image-row .image-item {
  position: relative;
  flex-basis: calc(50% - 20px);
  /* Adjusted to allow flexible width */
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* Prevents overflow */
  /* Circle label styling */
}
.opinion-container .image-container .image-row .image-item .image-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  /* Ensures the container takes up full width */
}
.opinion-container .image-container .image-row .image-item .image-wrapper img {
  display: block;
  width: 100%;
  /* Image will grow/shrink to fit its container */
  height: auto;
  max-height: 256px;
  object-fit: contain;
  /* Ensures the image covers the container */
  max-width: 380px;
  /* Prevents image from overflowing */
  border-radius: 0.8vh;
}
.opinion-container .image-container .image-row .image-item .image-wrapper .image-label-circle {
  position: absolute;
  /* Adjusted to allow flexible width */
  bottom: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px #3967AD;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  z-index: 1;
}
.opinion-container .image-container .image-row .image-item .image-wrapper .image-circle-water {
  position: absolute;
  /* Adjusted to allow flexible width */
  bottom: -42px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px #3967AD;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
  z-index: 1;
}
.opinion-container .image-container .image-row .image-item .image-wrapper p {
  margin-top: 10px;
  color: var(--text-text-secondary, #333);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
  font-size: clamp(12px, 2vw, 16px);
  word-wrap: break-word;
  text-align: center;
  flex-shrink: 1;
}
.opinion-container .chat-wrapper {
  max-height: 40%;
  height: 243.01px;
}
.opinion-container .chat-wrapper .counters-and-microphone {
  margin-top: 8%;
  width: 299.3px;
  height: 243.01px;
  max-height: 300px;
}
.opinion-container .chat-wrapper .microphone-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 8%;
  height: 243.01px;
  max-height: 300px;
}
.opinion-container .chat-wrapper .microphone-wrapper .recording-message {
  color: var(--accents-accent-03-medium, #003057);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
}
.opinion-container .chat-wrapper .microphone-wrapper .microphone-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  max-height: 200px;
  min-height: 176px;
}
.opinion-container .chat-wrapper .microphone-wrapper .microphone-container .microphone {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.opinion-container .chat-wrapper .microphone-wrapper .microphone-container .featuredChart {
  position: absolute;
  width: 132px !important;
  flex-shrink: 1;
  z-index: 100;
}
.opinion-container .chat-wrapper .microphone-wrapper .microphone-container .microphone-toggle {
  position: absolute;
}
.opinion-container .chat-wrapper .count-down-text {
  width: 380px;
  text-align: center;
  margin-bottom: 5%;
  color: var(--accents-accent-03-medium, #003057);
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  z-index: 24;
  position: absolute;
  bottom: 25px;
}

.hide {
  display: none !important;
}

.user-id-watermark {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center it */
  font-size: 2rem; /* Large font size for watermark */
  color: rgba(0, 0, 0, 0.1); /* Light, subtle color */
  pointer-events: none; /* Make it non-interactive */
  user-select: none; /* Prevent text selection */
  z-index: -1; /* Ensure it's behind other elements */
}

.chat-container-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-height: 100%;
  /* Adjust as needed to fit your layout */
  width: 100%;
  max-width: 512px;
  /* Keep this consistent with your chat history width */
  border: 1px solid #ccc;
  padding: 10px;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  background-color: #FBFCFE;
  /************************/
  border-radius: 0.8vh !important;
  /************************/
  position: relative;
  /*****************************************/
  /* Fading effect at the top */
  /************** Reused End *************/
}
.chat-container-text .chat-history-container {
  position: relative;
  /* Ensure the pseudo-element is positioned correctly */
  overflow-x: hidden;
  overflow-y: auto;
  /* Allow vertical scrolling */
  max-height: 316px;
  /* Adjust as needed for your layout */
  background-color: #FBFCFE;
  width: 100%;
  /* Optional padding for spacing */
}
.chat-container-text .chat-history-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgb(249, 249, 249), rgba(249, 249, 249, 0));
  /***********************/
  /* The height of the fade effect */
  /***********************************/
  pointer-events: none;
  /* Allow interactions through the fade */
  z-index: 1;
  /* Place on top of the chat history */
}
.chat-container-text .chat-history {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  /* Fill remaining space */
  width: 100%;
  padding: 0 12px;
  padding-left: 24px;
  padding-bottom: 10px;
  /* Ensure consistent spacing */
  /************ Reused Start ****************/
  /************************/
}
.chat-container-text .chat-history .chat-message {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  position: relative;
}
.chat-container-text .chat-history .chat-message .chat-text {
  color: #2B6BBE;
  padding-left: 30px;
}
.chat-container-text .chat-history .chat-message.user {
  display: flex;
  justify-content: flex-end;
}
.chat-container-text .chat-history .chat-message.user .chat-bubble {
  background-color: #fafafa;
  color: #722351;
  border-color: #722351;
  border-width: 1px;
  border-bottom-right-radius: 0;
  position: relative;
  margin-right: 30px;
}
.chat-container-text .chat-history .chat-message.user .chat-bubble::before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  background: #fafafa;
  border: 1px #722351 solid;
  right: -12px;
  z-index: 0;
  bottom: -1px;
  transform: skewX(45deg);
  border-top: transparent;
  border-left: transparent;
  clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 0);
  border-bottom-right-radius: 0.8vh;
}
.chat-container-text .chat-history .chat-message.assistant {
  display: flex;
  justify-content: flex-start;
}
.chat-container-text .chat-history .chat-message.assistant .chat-bubble {
  display: flex;
  background-color: #eff7ff;
  color: #2B6BBE;
  border-color: #2B6BBE;
  border-bottom-left-radius: 0;
  position: relative;
  margin-left: 20px;
}
.chat-container-text .chat-history .chat-message.assistant .chat-bubble::before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  background: #eff7ff;
  border: 2px #2B6BBE solid;
  left: -12.5px;
  z-index: 0;
  bottom: -2px;
  transform: scaleY(-1) skewX(45deg) rotate(180deg);
  border-top: transparent;
  border-left: transparent;
  clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 0);
  border-bottom-right-radius: 0.8vh;
}
.chat-container-text .chat-history .chat-bubble {
  width: 85%;
  text-align: left;
  padding: 16.5px 22px 16px 22px;
  border-radius: 10px;
  border: 2px solid #000;
  position: relative;
  font-family: Arial, sans-serif;
}
.chat-container-text .chat-input {
  display: flex;
  width: 100%;
  padding: 10px;
  padding-right: 24px;
  /****************/
  padding-left: 24px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  /******************/
}
.chat-container-text .chat-input textarea {
  overflow: hidden;
  display: flex;
  padding: 16px 24px;
  max-height: 70px;
  align-items: center;
  justify-content: center;
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}
.chat-container-text .chat-input textarea::placeholder {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}

.chatbot-wrapper {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
  padding: 24px 50px;
  padding-bottom: 0;
  padding-top: 0px;
}
.chatbot-wrapper span.title,
.chatbot-wrapper span.details,
.chatbot-wrapper span.instructions {
  color: var(--text-text-secondary, #333);
  font-feature-settings: "clig" off, "liga" off;
  flex-shrink: 1;
  line-height: 150%;
}
.chatbot-wrapper span.title {
  font-size: 24px;
  font-weight: 700;
}
.chatbot-wrapper span.details,
.chatbot-wrapper span.instructions {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Open Sans";
  white-space: normal;
  width: 100%;
  height: 100%;
}
.chatbot-wrapper span.details {
  margin-top: 3%;
}
.chatbot-wrapper span.instructions {
  margin-top: 4%;
}
.chatbot-wrapper .microphone-container {
  height: 100%;
}

.chat-container-audio {
  overflow: hidden;
  padding: 10px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 10px;
  width: 100%;
  max-width: 512px;
  height: 100%;
}
.chat-container-audio .microphone-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 15px;
}
.chat-container-audio .microphone-toggle .system-response {
  position: relative;
  height: 50%;
  width: 100%;
  padding-top: 24px;
}
.chat-container-audio .microphone-toggle .system-response .headphones {
  position: absolute;
  top: 0;
  left: calc(50% - 85px);
}
.chat-container-audio .microphone-toggle .microphone-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
}
.chat-container-audio .microphone-toggle .speak-timer-container {
  margin-top: 5px !important;
}
.chat-container-audio .microphone-toggle .silent {
  width: 100%;
  height: 100%;
}
.chat-container-audio .microphone-toggle .dyyMAz {
  width: 100%;
  height: 100%;
}

.toast-container {
  bottom: 5rem !important;
  left: calc(50% + 50px) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #2B6BBE !important;
  width: 250px;
}
.Toastify__toast-theme--colored.Toastify__toast--error div.Toastify__toast-body div {
  color: white !important;
}

.Toastify__toast Toastify__toast-theme--colored Toastify__toast--error button.Toastify__close-button.Toastify__close-button--colored svg {
  fill: white !important;
}

.Toastify__toast-container.Toastify__toast-container--bottom-center .Toastify__toast.Toastify__toast-theme--colored.Toastify__toast--error svg {
  fill: white !important;
  color: white !important;
}
